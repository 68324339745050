import React from "react"
import { Grid, Typography, Container, Box, Link } from "@mui/material"

import { StaticImage } from "gatsby-plugin-image"

import { Center, FancyHr } from "../Layout"
import { AnimateOnScroll } from "../AnimateOnScroll"

import ProgramsHero from "./ProgramsHero"
import { Segment } from "../Segment"

const Programs = () => {
  return (
    <>
      <ProgramsHero />
      <Segment>
        <AnimateOnScroll animateIn="fadeIn" animateOut="fadeIn">
          <Container>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h4" color="primary" align="center">
                  Boule' Scholars Program
                </Typography>
              </Grid>
            </Grid>
            <Grid container mb={4}>
              <Grid item xs={12}>
                <Center>
                  <StaticImage
                    src="../../images/epsilon-logo-boule-scholars.jpg"
                    alt="elb logo"
                    placeholder="blurred"
                    width={550}
                  />
                </Center>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Typography color="primary" align="center">
                  Learn moe at{" "}
                  <Link
                    href="https://www.elbfoundation.org/boule-scholars"
                    target="_blank"
                  >
                    www.boulescholars.com
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </AnimateOnScroll>
      </Segment>
      <Segment variant="primary">
        <AnimateOnScroll animateIn="fadeIn" animateOut="fadeIn">
          <Container>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h4" color="common.white" align="center">
                  Exempt organizations under section 501 (c)(3) view again the
                  ‘green stillness of the country.’”
                </Typography>
                <FancyHr alt />
                <Box mb={2}>
                  <Typography color="common.white" align="center">
                    As stated in its Articles of Incorporation the corporation
                    is incorporated exclusively for charitable, religious,
                    educational and scientific purposes, including, for such
                    purposes, the making of distribution to organizations that
                    qualify as exempt organizations under section 501 (c)(3) of
                    the Internal Revenue Code or corresponding section of any
                    future federal tax code.
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography color="common.white" align="center">
                    The membership of the Corporation shall be limited to
                    individuals who are active members of the Epsilon Lamda
                    Boule of Sigma Pi Phi Fraternity.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </AnimateOnScroll>
      </Segment>
      <Segment>
        <AnimateOnScroll animateIn="fadeIn" animateOut="fadeIn">
          <Container>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h4" color="primary" align="center">
                  The Great Give
                </Typography>
                <FancyHr />
                <Typography align="center">
                  The ELB Foundation was afforded the opportunity by the United
                  Way of Palm Beach County to participate in the “Great Give”
                  Program. Our foundation is now eligible to receive donations
                  as well as matching contributions during this, the largest
                  annual 24 hour online giving event in our community. Secondly,
                  the Epsilon Lambda Boule Foundation in conjunction with the
                  Grand Social Action Committee and PGA of America served as a
                  national pilot program for the Sigma Pi Phi PGA Junior League
                  Golf Program. This partnership provides PGA golf instruction,
                  uniforms, fees, and equipment at no cost to our youth
                  participants. This partnership has benefitted seven member
                  boule’s and 240 minority children nationwide in 2016 and we
                  are looking to expand the program in the years to come.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </AnimateOnScroll>
      </Segment>
      <Segment variant="primary">
        <AnimateOnScroll animateIn="fadeIn" animateOut="fadeIn">
          <Container>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h4" color="common.white" align="center">
                  Sigma Pi Phi PGA Junior League Golf Program
                </Typography>
                <FancyHr alt />
                <Box mb={2}>
                  <Typography color="common.white" align="center">
                    The ELB Foundation in conjunction with the Grand Social
                    Action Committee and PGA of America served as a national
                    pilot program for the Sigma Pi Phi PGA Junior League Golf
                    Program. This partnership provides PGA golf instruction,
                    uniforms, fees, and equipment at no cost to our youth
                    participants. This partnership has benefitted seven member
                    boule’s and 240 minority children nationwide in 2016 and we
                    are looking to expand the program in the years to come.
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography color="common.white" align="center">
                    The membership of the Corporation shall be limited to
                    individuals who are active members of the Epsilon Lamda
                    Boule of Sigma Pi Phi Fraternity.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <StaticImage
                  src="../../images/golf/golf-1.jpg"
                  alt="elb logo"
                  placeholder="blurred"
                  width={550}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <StaticImage
                  src="../../images/golf/golf-2.jpg"
                  alt="elb logo"
                  placeholder="blurred"
                  width={550}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <StaticImage
                  src="../../images/golf/golf-3.jpg"
                  alt="elb logo"
                  placeholder="blurred"
                  width={550}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <StaticImage
                  src="../../images/golf/golf-4.jpg"
                  alt="elb logo"
                  placeholder="blurred"
                  width={550}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <StaticImage
                  src="../../images/golf/golf-5.jpg"
                  alt="elb logo"
                  placeholder="blurred"
                  width={550}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <StaticImage
                  src="../../images/golf/golf-6.jpg"
                  alt="elb logo"
                  placeholder="blurred"
                  width={550}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <StaticImage
                  src="../../images/golf/golf-7.jpg"
                  alt="elb logo"
                  placeholder="blurred"
                  width={550}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <StaticImage
                  src="../../images/golf/golf-8.jpg"
                  alt="elb logo"
                  placeholder="blurred"
                  width={550}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <StaticImage
                  src="../../images/golf/golf-9.jpg"
                  alt="elb logo"
                  placeholder="blurred"
                  width={550}
                />
              </Grid>
            </Grid>
          </Container>
        </AnimateOnScroll>
      </Segment>
    </>
  )
}
export default Programs
